import React, {useEffect, useState} from "react";
import {
    ColumnDef,
    FiltersType,
    OrderItemsType,
    OrderSheet,
    ProductFilter,
    ProductForTable,
    TableDataType,
} from "./../../types/order-sheet";
import "./ProductTable.scss";

import "react-toastify/dist/ReactToastify.css";
import Popover from '@mui/material/Popover';
import Sort from "./Icons/Sort";
import SortDesc from "./Icons/SortDesc";
import SortAsc from "./Icons/SortAsc";
import Footer from "./Footer";
import TableActionHeaders from "./TableActionHeaders";
import {
    getPriceWithOrdersheetStoreSync,
    getTotalRowPrice,
    getTotalRowQty, updateCalculatedValues
} from "../../utils/get-price-with-ordersheet-store-sync";
import {maxSampleTesterValue} from "../../utils/validations";
import {useCompany} from "../../context/CompanyContext";
import {SAMPLE_UNIT_PRICE} from "../../constants";
import {CONVERSION_FREE_CURRENCIES} from "../../constants/s3";
import {HintRenderer, Hints} from "./Hints";
import getColumns from "./ColumnDef";
import {Link} from "react-router-dom";

function ProductTable({
                          orderSheetDataFromAPI,
                          orderItemsInitial,
                          handleSaveDraft,
                          handleDownloadOrdersheet,
                          handleSendMail,
                          vatRatePercent
                      }: {
    orderSheetDataFromAPI: OrderSheet,
    orderItemsInitial: OrderItemsType,
    handleDownloadOrdersheet: (orderItems: OrderItemsType) => void,
    handleSendMail: (orderItems: OrderItemsType) => void,
    handleSaveDraft: (orderItems: OrderItemsType) => void,
    vatRatePercent: number
}) {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const {
        currencyInfo: {exchangeRateFactor, baseCurrency},
        exportOptions: {currency},
    } = orderSheetDataFromAPI;

    const {formattedData, priceComposition} = getPriceWithOrdersheetStoreSync(
        orderSheetDataFromAPI,
        orderItemsInitial,
        currency,
        exchangeRateFactor
    );

    const selectFilterOptionsData: ProductFilter = formattedData.reduce(
        (acc, product) => {
            if (!acc.category.includes(product.category)) {
                acc.category.push(product.category);
            }
            if (!acc.brand.includes(product.brand)) {
                acc.brand.push(product.brand);
            }
            if (!acc.color.includes(product.colorName)) {
                acc.color.push(product.colorName);
            }
            return acc;
        },
        {
            category: [] as string[],
            brand: [] as string[],
            color: [] as string[],
        }
    );

    const [sortedField, setSortedField] = React.useState<{
        key: keyof ProductForTable;
        direction: "asc" | "desc";
    } | null>({
        key: "orderQty",
        direction: "desc",
    });

    const sortProducts = (a: ProductForTable, b: ProductForTable, sortedField: {
        key: keyof ProductForTable;
        direction: "asc" | "desc"
    }) => {
        const field = sortedField.key;
        if (a[field] === b[field])
            return 0;
        const cmp = (a: string | number | null, b: string | number | null) => {
            if (a === null)
                return -1
            if (b === null)
                return 1
            return a < b ? -1 : 1;
        };
        return cmp(a[field] ?? null, b[field] ?? null) * (sortedField.direction === "desc" ? -1 : 1);
    }

    const [orderSheetTableData, setOrderSheetTableData] = useState<TableDataType>(
        {
            disableButtons: false,
            priceComposition: priceComposition,
            data: sortedField
                ? formattedData.sort((a, b) => sortProducts(a, b, sortedField))
                : formattedData,
        }
    );

    const [filters, setFilters] = React.useState<FiltersType>({
        text: "",
        select: {
        },
    });

    useEffect(() => {
        if (sortedField?.key) {
            const sorted = [...orderSheetTableData.data].sort((a, b) => sortProducts(a, b, sortedField));
            setOrderSheetTableData({
                ...orderSheetTableData,
                data: sorted,
            });
        } else {
            const orderSheetData = getPriceWithOrdersheetStoreSync(
                orderSheetDataFromAPI,
                getOrderItems(),
                currency,
                exchangeRateFactor
            );
            setOrderSheetTableData({
                data: orderSheetData.formattedData,
                priceComposition: orderSheetData.priceComposition,
                disableButtons: false,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortedField]);

    const getOrderItems = (withAllData: boolean = false): OrderItemsType =>
        orderSheetTableData.data
            .filter((product) => (withAllData || product.orderQty > 0))
            .reduce((acc, product) => {
                acc[product.variantProductId] = {
                    qty: product.orderQty,
                    qtySample: product.sampleQty ?? 0,
                    qtyTester: product.testerQty ?? 0
                };
                return acc;
            }, {} as OrderItemsType);

    const resetForm = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const orderSheetData = getPriceWithOrdersheetStoreSync(
            orderSheetDataFromAPI,
            {},
            currency,
            exchangeRateFactor
        );
        setOrderSheetTableData({
            data: orderSheetData.formattedData,
            priceComposition: {
                products: 0,
                samples: 0,
                testers: 0,
            },
            disableButtons: false,
        });
    };

    const downloadOrdersheet = async (
        e: React.MouseEvent<HTMLLIElement, MouseEvent>,
        withAllData: boolean
    ) => {
        e.preventDefault();
        if (!orderSheetTableData.disableButtons) {
            setOrderSheetTableData({
                ...orderSheetTableData,
                disableButtons: true,
            });
        }
        await handleDownloadOrdersheet(getOrderItems(withAllData));
        setOrderSheetTableData({
            ...orderSheetTableData,
            disableButtons: false,
        });
    };

    const saveDraft = async (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        e.preventDefault();
        if (!orderSheetTableData.disableButtons) {
            setOrderSheetTableData({
                ...orderSheetTableData,
                disableButtons: true,
            });
        }

        await handleSaveDraft(getOrderItems());
        setOrderSheetTableData({
            ...orderSheetTableData,
            disableButtons: false,
        });
    };

    const sendMail = async (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        e.preventDefault();

        if (!orderSheetTableData.disableButtons) {
            setOrderSheetTableData({
                ...orderSheetTableData,
                disableButtons: true,
            });
        }
        await handleSendMail(getOrderItems());
        setOrderSheetTableData({
            ...orderSheetTableData,
            disableButtons: false,
        });
    };

    const requestSort = (accessorKey: keyof ProductForTable) => {
        if (
            sortedField &&
            sortedField.key === accessorKey &&
            sortedField.direction === "asc"
        ) {
            setSortedField({key: accessorKey, direction: "desc"});
        } else if (sortedField && sortedField.key === accessorKey) {
            setSortedField(null);
        } else {
            setSortedField({key: accessorKey, direction: "asc"});
        }
    };

    const handleUpdateOrderQty = (
        event: { currentTarget: { value: string } },
        product: ProductForTable
    ) => {
        product.orderQty = Math.min(
          Math.max(0, parseInt(event.currentTarget.value) || 0),
          1000
        );
        product.sampleQty = maxSampleTesterValue(product) < product.sampleQty ? maxSampleTesterValue(product) : product.sampleQty;
        product.testerQty = maxSampleTesterValue(product) < product.testerQty ? maxSampleTesterValue(product) : product.testerQty;
        updateRows();
    };

    const handleUpdateSampleQty = (
        event: { currentTarget: { value: string } },
        product: ProductForTable
    ) => {
        const value = Math.min(Math.max(0, parseInt(event.currentTarget.value) || 0), 1000);
        const max = maxSampleTesterValue(product);
        const sampleQty = max < value ? max : value;
        if (sampleQty !== product.sampleQty) {
            product.sampleQty = maxSampleTesterValue(product) > sampleQty ? sampleQty : maxSampleTesterValue(product);
            updateRows();
        }
    };

    const handleUpdateTesterQty = (
        event: { currentTarget: { value: string } },
        product: ProductForTable
    ) => {
        const value = Math.min(Math.max(0, parseInt(event.currentTarget.value) || 0), 1000);
        const max = maxSampleTesterValue(product);
        const testerQty = max < value ? max : value;
        if (testerQty !== product.testerQty) {
            product.testerQty = maxSampleTesterValue(product) > testerQty ? testerQty : maxSampleTesterValue(product);
            updateRows();
        }
    };

    const updateRows = () => {
        const updatedComposition = updateCalculatedValues(
            orderSheetTableData.data,
            currency,
            exchangeRateFactor,
            orderSheetDataFromAPI.exportOptions.discount ?? null,
            orderSheetDataFromAPI.productPricesDontIncludeDiscount !== true
        );
        setOrderSheetTableData({
            data: orderSheetTableData.data,
            priceComposition: updatedComposition,
            disableButtons: false,
        });
    };

    const validatewithSelectedFilters = (product: ProductForTable) => (
        (
            filters.text.length === 0 ||
            (product.name && product.name.toLowerCase().includes(filters.text.toLowerCase())) ||
            (product.category && product.category.toLowerCase().includes(filters.text.toLowerCase())) ||
            (product.variantProductId && product.variantProductId.toLowerCase().includes(filters.text.toLowerCase())) ||
            (product.customerProductCode && product.customerProductCode.toLowerCase().includes(filters.text.toLowerCase())) ||
            (product.ean && product.ean.toLowerCase().includes(filters.text.toLowerCase()))
        ) &&
        (filters.select.color?.includes(product.colorName) ?? true) &&
        (filters.select.category?.includes(product.category) ?? true) &&
        (filters.select.brand?.includes(product.brand) ?? true)
    );
    const {selectedCompany} = useCompany();

    const footNotes: Hints = {
        priceHint: {
            hint: ["EUR", "USD"].includes(currency)
                    ? 'Prices are based on Incoterm 2020 CIP and will be revised regularly.'
                    : 'Payment must be made in US dollars using the exchange rate on March 12, 2023. Prices are based on Incoterm 2020 CIP and will be revised regularly.',
            condition: selectedCompany.id !== 'fnf',
        },
        sampleHint: {
            hint: `Each sample is charged with a token fee of ${SAMPLE_UNIT_PRICE} ${CONVERSION_FREE_CURRENCIES.includes(currency) ? currency : baseCurrency}. We will refund this fee on your next order.`,
            condition: selectedCompany.id !== 'fnf',
        },
        discountHint: {
            hint: 'Discount does not apply to samples.',
            condition: !!(selectedCompany.id !== 'fnf' && orderSheetDataFromAPI.exportOptions.discount),
        },
        zoneHint: {
            hint: <span>Please see <Link to="/o/zones">Zone List</Link></span>,
            condition: false, // TODO: enable once ready
        }
    }

    const columns = getColumns(
        orderSheetDataFromAPI.exportOptions.currency,
        vatRatePercent,
        orderSheetDataFromAPI.currencyInfo.baseCurrency,
        orderSheetDataFromAPI.products.some(p => p.variants.some(v => v.customerProductCode !== null)),
        orderSheetDataFromAPI.exportOptions.discount ?? null,
        new HintRenderer(footNotes),
        selectedCompany
    );

    return (
        <div className="product-table">
            <TableActionHeaders
                setFilters={setFilters}
                filters={filters}
                setSortedField={setSortedField}
                disableButtons={orderSheetTableData.disableButtons}
                sendMail={sendMail}
                downloadOrdersheet={downloadOrdersheet}
                resetForm={resetForm}
                saveDraft={saveDraft}
            />
            <div className="product-table__table-wrap">
                <table>
                    <thead>
                    <tr>
                        {columns.map((column) => (
                                <th
                                    aria-owns={column?.title && open !== undefined ? 'mouse-over-popover' : undefined}
                                    aria-haspopup={column?.title !== undefined ? "true" : undefined}
                                    onMouseEnter={column?.title !== undefined ? handlePopoverOpen : undefined}
                                    onMouseLeave={column?.title !== undefined ? handlePopoverClose : undefined}
                                    key={`${column.accessorKey}_thead_th`}
                                    colSpan={column.colSpan || undefined}
                                    className={
                                        column.accessorKey +
                                        (column.sortable ? " clickable" : "") +
                                        (column.selectFilter && column.accessorKey &&
                                        selectFilterOptionsData[column.accessorKey]
                                            ? " select_th"
                                            : "")
                                    }
                                >
                                    <div
                                        className={
                                            column.selectFilter && column.accessorKey &&
                                            selectFilterOptionsData[column.accessorKey]
                                                ? "select_filter_header_div"
                                                : ""
                                        }
                                        onClick={() =>
                                            column.sortable === true && column.accessorKey
                                                ? requestSort(column.accessorKey)
                                                : ""
                                        }
                                    >
                        <span>
                          {column.header}
                        </span>
                                        {column.sortable ? (
                                            sortedField?.key === column.accessorKey ? (
                                                sortedField.direction === "asc" ? (
                                                    <SortAsc/>
                                                ) : (
                                                    <SortDesc/>
                                                )
                                            ) : (
                                                <Sort/>
                                            )
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    {column?.subHeader !== undefined &&
                                        <span className="subHeader">{column.subHeader} </span>}
                                    {column.selectFilter && column.accessorKey &&
                                        selectFilterOptionsData[column.accessorKey] && (
                                            <div className="select_filter_div">
                                                <select
                                                    value={filters.select[column.accessorKey]}
                                                    onChange={(event) => {
                                                        if (column.accessorKey) {
                                                            setFilters({
                                                                ...filters,
                                                                select: {
                                                                    ...filters.select,
                                                                    [column.accessorKey]: event.target.value ? [event.target.value] : undefined,
                                                                },
                                                            });
                                                        }
                                                    }}
                                                >
                                                    <option value="">All</option>
                                                    {selectFilterOptionsData[column.accessorKey]?.sort()
                                                        .map((filter) => (
                                                            <option
                                                                key={
                                                                    filter + "select_filter_" + column.accessorKey
                                                                }
                                                            >
                                                                {filter}
                                                            </option>
                                                        ))}
                                                </select>
                                            </div>
                                        )}
                                    {column?.title !== undefined && (<Popover
                                        sx={{pointerEvents: 'none'}}
                                        open={open}
                                        anchorEl={anchorEl}
                                        onClose={handlePopoverClose}
                                        classes={{paper: 'isSample_popover_paper'}}
                                        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                                        transformOrigin={{vertical: 'bottom', horizontal: 'center'}}
                                    >
                                        <p className="isSample_popover">
                                            {column.title}
                                        </p>
                                    </Popover>)}
                                </th>
                            )
                        )}
                    </tr>
                    </thead>
                    <tbody>
                    {orderSheetTableData.data.filter(validatewithSelectedFilters).map((product, i) => {
                        const onFocusSelectAll = (event: any) => event?.target.select();
                        return (
                            <tr key={`${product.variantProductId}_tbody_tr`}>
                                {columns.map((column) => {
                                    let row = null;
                                    if (column.accessorKey === "orderQty")
                                        row = (
                                            <input
                                                key={`${product.variantProductId}_input_${column.accessorKey}`}
                                                type="number"
                                                value={product.orderQty}
                                                onFocus={onFocusSelectAll}
                                                onChange={(event) =>
                                                    handleUpdateOrderQty(event, product)
                                                }
                                            />
                                        );
                                    else if (column.accessorKey === "sampleQty")
                                        row = product.isSample ? (
                                            <input
                                                key={`${product.variantProductId}_input_${column.accessorKey}`}
                                                type="number"
                                                value={product.sampleQty}
                                                onFocus={onFocusSelectAll}
                                                onChange={(event) => handleUpdateSampleQty(event, product)}
                                                disabled={maxSampleTesterValue(product) === 0}
                                            />
                                        ) : (<>-</>);
                                    else if (column.accessorKey === "testerQty")
                                        row = product.isTester ? (
                                            <input
                                                key={`${product.variantProductId}_input_${column.accessorKey}`}
                                                type="number"
                                                value={product.testerQty}
                                                onFocus={onFocusSelectAll}
                                                onChange={(event) => handleUpdateTesterQty(event, product)}
                                                disabled={maxSampleTesterValue(product) === 0}
                                            />
                                        ) : (<>-</>);
                                    else if (column.cell)
                                        row = column.cell(`${product.variantProductId}_cell_${column.accessorKey}`, product, currency, exchangeRateFactor);
                                    else
                                        row = product[column.accessorKey as keyof ProductForTable]

                                    if (column.colSpan) {
                                        return row;
                                    } else {
                                        return (
                                            <td key={`${product.variantProductId}_tbody_td_${column.accessorKey}`}>
                                                {row}
                                            </td>
                                        );
                                    }
                                })}
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>
            <Footer
                orderSheetTableData={orderSheetTableData}
                currency={currency}
                exchangeRateFactor={exchangeRateFactor}
                baseCurrency={baseCurrency}
                discount={orderSheetDataFromAPI.exportOptions.discount ?? null}
                hints={new HintRenderer(footNotes)}
            />
        </div>
    );
}

export default ProductTable;
