/* eslint-disable react/style-prop-object */
import React, {useEffect, useState} from "react";
import Header from "./Header";
import "./Privacy.scss";
import {useCompany} from "../context/CompanyContext";
import axios from "axios";
import LoadingSpinner from "./LoadingSpinner";

const headerLeftContent = (
  <>
    <h1>Zone Information</h1>
  </>
);
type ZoneInfo = {
    code: string;
    availableCountries: string[];
}
const Zones = () => {
    const {selectedCompany} = useCompany();
    const [zoneInfo, setZoneInfo] = useState<ZoneInfo[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        (async () => {
            const res = await axios.get(
                [
                    "/api/zones",
                    '?companyName=',
                    selectedCompany.name
                ].join(''),
            );
            setZoneInfo(res.data);
            setIsLoading(false);
        })()
    }, [selectedCompany]);

  return (
      <div>
          <Header leftContent={headerLeftContent}/>

          {isLoading ? <LoadingSpinner/> : (<table>
              <thead>
              <tr>
                  <th>
                      ZONE
                  </th>
                  <th>
                      COUNTRIES
                  </th>
              </tr>
              </thead>
              <tbody>
              {zoneInfo.map(item =>
                  <tr>
                      <td>
                          <b>{item.code}</b>
                      </td>
                      <td>
                          {item.availableCountries.join(', ')}
                      </td>
                  </tr>
              )}
              </tbody>
          </table>)}
      </div>
  );
};

export default Zones;
